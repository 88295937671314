import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Alert,
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Collapse,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  Description,
  Event,
  Expand,
  ExpandLess,
  ExpandMore,
  FileOpen,
  HomeOutlined,
  InboxOutlined,
  LibraryAdd,
  ListAlt,
  LocalLibrary,
  PhotoLibrary,
  VideoLibrary,
} from "@mui/icons-material";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import { Layout } from "./pages/Layout";
import { ClubSites } from "./pages/club-sites/ClubSites";
import { AddSite } from "./pages/club-sites/AddSite";
import { Login } from "./pages/Login";
import { fetchWithAuth, getAccessToken } from "./components/util/login";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/club-sites" replace />} />
          <Route path="/login" element={<Login />} />
          <Route element={<AuthLayout />}>
            <Route path="/" element={<Layout />}>
              <Route path="club-sites" element={<ClubSites />} />
              <Route path="club-sites/add" element={<AddSite />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const AuthLayout = () => {
  const [hasLogin, setHasLogin] = useState<boolean>();

  const current = async () => {
    await getAccessToken();
    fetchWithAuth("/api/clubadmin/current")
      .then((res) => res.json())
      .then((data) => {
        if (data.error === true) {
          setHasLogin(false);
        } else {
          setHasLogin(true);
        }
      });
  };

  useEffect(() => {
    current();
  }, []);

  return (
    <div>
      {hasLogin === undefined && <div className="loading"></div>}
      {hasLogin === false && <Alert>You need to login</Alert>}
      {hasLogin && <Outlet />}
    </div>
  );
};

export default App;
