import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { fetchWithAuth } from "../../components/util/login";

export const UpdateClub = (props: {
  data: any;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { data } = props;

  const [urlType, setUrlType] = useState(data.host ? "host" : "path");
  const [host, setHost] = useState(data.host);
  const [path, setPath] = useState(data.basePath);

  const confirm = () => {
    let params = "";
    if (urlType === "host") {
      params = "host=" + host;
    } else {
      params = "path=" + path;
    }
    fetchWithAuth(
      "/api/clubadmin/site/update_site?id=" + data.id + "&" + params
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error === false) {
          window.alert("Success");
          props.onSuccess();
        } else {
          window.alert(data.data);
        }
      });
  };

  return (
    <div>
      <DialogContent>
        <FormGroup>
          <RadioGroup
            row
            value={urlType}
            onChange={(e) => setUrlType(e.target.value)}
          >
            <FormControlLabel value="host" control={<Radio />} label="Host" />
            <FormControlLabel value="path" control={<Radio />} label="Path" />
          </RadioGroup>
          <br />
          <br />
          {urlType === "host" && (
            <TextField
              required
              InputLabelProps={{ shrink: true }}
              label="Host"
              value={host}
              placeholder="eg. hjerteress.com"
              onChange={(e) => setHost(e.target.value)}
            />
          )}
          {urlType === "path" && (
            <TextField
              required
              InputLabelProps={{ shrink: true }}
              label="Path"
              placeholder="eg. if '704', the site will be bridge.no/704"
              value={path}
              onChange={(e) => setPath(e.target.value)}
            />
          )}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <div>
          <Button variant="text" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={confirm}>
            Confirm
          </Button>
        </div>
      </DialogActions>
    </div>
  );
};
