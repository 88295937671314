import { ErrorOutline, Warning } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { fetchWithAuth } from "../util/login";

export const AssignUser = (props: {
  data: any;
  onCancel: () => void;
  onSuccess: () => void;
}) => {
  const [memberNumber, setMemberNumber] = useState("");
  const [member, setMember] = useState<any>(false);
  const [result, setResult] = useState<any>("");

  const check = () => {
    fetchWithAuth(
      "/api/clubadmin/users/get_member?member_number=" + memberNumber
    )
      .then((res) => res.json())
      .then((data: any) => {
        setMember(data.data);
        setResult("");
      });
  };

  const assign = () => {
    fetchWithAuth(
      "/api/clubadmin/users/assign?org_id=" +
        props.data.org_id +
        "&member_number=" +
        memberNumber +
        "&site_type=" +
        props.data.site_type +
        "&member_name=" +
        member.first_name +
        " " +
        member.last_name +
        "&site_id=" +
        props.data.id
    )
      .then((res) => res.json())
      .then((data: any) => {
        if (data.error === false) {
          setResult(true);
          props.onSuccess();
        } else {
          setResult("Failed to add: " + data.data);
        }
      });
  };

  return (
    <div>
      <DialogContent>
        <Box>
          <label>Member number: </label>
          <TextField
            size="small"
            type="number"
            style={{ width: 150 }}
            onChange={(e) => setMemberNumber(e.target.value)}
          />
          <Button sx={{ ml: 1 }} variant="outlined" onClick={check}>
            Check member
          </Button>
        </Box>
        <Typography>
          {member !== false && (
            <Box sx={{ mt: 2 }}>
              {member && (
                <Alert>
                  <div>Number: {member.user_membernumber}</div>
                  <div>
                    Name: {member.first_name} {member.last_name}
                  </div>
                </Alert>
              )}
              {!member && (
                <Alert color="warning" icon={<Warning />}>
                  Member not found.
                </Alert>
              )}
            </Box>
          )}

          {result !== "" && (
            <Alert color="warning" sx={{ mt: 2 }} icon={<ErrorOutline />}>
              <div>{result}</div>
            </Alert>
          )}
        </Typography>
      </DialogContent>

      <DialogActions>
        {member && (
          <Button variant="contained" onClick={assign}>
            Confirm
          </Button>
        )}
        <Button sx={{ ml: 1 }} variant="text" onClick={() => props.onCancel()}>
          Cancel
        </Button>
      </DialogActions>
    </div>
  );
};
