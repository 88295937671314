import {
  Description,
  ExpandLess,
  HomeOutlined,
  ListAlt,
  Event,
  PhotoLibrary,
  ExpandMore,
  LinkRounded,
  LaunchOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  CssBaseline,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router";
import { NavLink } from "react-router-dom";

const Layout = () => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Drawer
          sx={{
            width: 250,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 250,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Box sx={{ p: 1 }}>
            <a href="/">
              <img src="/logo_nbf.png" width={"80%"} />
            </a>
          </Box>
          <List component="nav">
            <NavLink to="/club-sites">
              <ListItemButton selected={true}>
                <ListItemIcon>
                  <HomeOutlined />
                </ListItemIcon>
                <ListItemText primary={"Hjemmesider"} />
              </ListItemButton>
            </NavLink>
            {/* <ListItemButton>
              <ListItemIcon>
                <HomeOutlined />
              </ListItemIcon>
              <ListItemText primary={"Krets sites"} />
            </ListItemButton> */}
            {/* <ListItemButton onClick={() => setOpen(!open)}>
              <ListItemIcon>
                <PhotoLibrary />
              </ListItemIcon>
              <ListItemText primary={"Club libraries"} />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <div>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List>
                  {[
                    ["Articles", <ListAlt />],
                    ["Events", <Event />],
                    ["Images", <PhotoLibrary />],
                    ["Files", <Description />],
                  ].map((item, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>{item[1]}</ListItemIcon>
                        <ListItemText primary={item[0]} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </div> */}
          </List>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              textAlign: "center",
              width: "100%",
              padding: 5,
            }}
          >
            <a href="https://admin.bridge.no">
              <Button>
                Back to clubbadmin
                <LaunchOutlined />
              </Button>
            </a>
          </div>
        </Drawer>

        <Outlet />
      </Box>
    </div>
  );
};

export { Layout };
