import { Alert, Container } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAccessToken, setToken } from "../components/util/login";

export const Login = () => {
  const [params] = useSearchParams();

  const token = params.get("token");
  const nav = useNavigate();

  useEffect(() => {
    if (token) {
      setToken(token);
      nav("/");
    }
  }, []);

  return (
    <div>
      <Container>
        {token && <div>Redirecting....</div>}
        {!token && <Alert>Need token</Alert>}
      </Container>
    </div>
  );
};
