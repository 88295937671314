import {
  Alert,
  Box,
  Button,
  Container,
  FormControlLabel,
  FormGroup,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { fetchWithAuth } from "../../components/util/login";

export const AddSite = () => {
  const [orgNo, setOrgNo] = useState("");
  const [host, setHost] = useState("");
  const [path, setPath] = useState("");
  const [siteType, setSiteType] = useState("club");
  const [loading, setLoading] = useState(false);
  const [clubInfo, setClubInfo] = useState<any>(null);
  const [urlType, setUrlType] = useState("host");

  const navigate = useNavigate();

  const submit = () => {
    setLoading(true);
    if (
      orgNo &&
      ((urlType === "host" && host) || (urlType === "path" && path))
    ) {
      let params = "";
      if (urlType === "host") {
        params = "host=" + host;
      } else if (urlType === "path") {
        params = "path=" + path;
      } else {
        return;
      }
      fetchWithAuth(
        "/api/clubadmin/site/create_site?orgno=" +
          orgNo +
          "&type=" +
          siteType +
          "&" +
          params
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.error === false) {
            if (data.data && data.data.id) {
              navigate("/club-sites");
            } else {
              window.alert("Something is wrong on server side. Try again.");
            }
          } else {
            window.alert(data.data);
          }
        });
    }
  };

  const check = () => {
    setClubInfo(null);
    if (orgNo) {
      setLoading(true);
      let params = "";
      if (urlType === "host") {
        params = "host=" + host;
      } else if (urlType === "path") {
        params = "path=" + path;
      } else {
        return;
      }
      if (siteType === "club") {
        fetchWithAuth(
          "/api/clubadmin/orginfo/club_info?clubid=" + orgNo + "&" + params
        )
          .then((res) => res.json())
          .then((data) => {
            setLoading(false);
            if (data.error === false) {
              setClubInfo(data.data);
            } else {
              window.alert(data.data);
            }
          });
      } else if (siteType === "krets") {
        fetchWithAuth(
          "/api/clubadmin/orginfo/krets_info?kretsid=" + orgNo + "&" + params
        )
          .then((res) => res.json())
          .then((data) => {
            setLoading(false);
            if (data.error === false) {
              setClubInfo(data.data);
            } else {
              window.alert(data.data);
            }
          });
      }
    } else if (siteType === "krets") {
    } else {
      window.alert("Need club no(number) and host.");
    }
  };

  return (
    <Container sx={{ p: 3 }}>
      <Typography variant="h4" component="h4">
        Ny side
      </Typography>
      <br />
      <Box sx={{ flexGrow: 1, bgcolor: "background.default" }}>
        <Stack>
          <label>Organization type: </label>
          <div>
            <label onClick={() => setSiteType("club")}>
              <Radio checked={siteType === "club"} />
              Clubs
            </label>
            <label onClick={() => setSiteType("krets")}>
              <Radio checked={siteType === "krets"} />
              Krets
            </label>
            <label onClick={() => setSiteType("")}>
              <Radio checked={siteType === ""} />
              Site without organization
            </label>
          </div>
        </Stack>
        {siteType && (
          <div>
            <FormGroup>
              <TextField
                InputLabelProps={{ shrink: true }}
                required
                disabled={clubInfo}
                label={siteType === "club" ? "Klubb No." : "Krets No."}
                type="number"
                value={orgNo}
                onChange={(e) => {
                  const v = e.target.value;
                  if (v) {
                    setOrgNo(parseInt(v) + "");
                  } else {
                    setOrgNo("");
                  }
                }}
              />
            </FormGroup>
          </div>
        )}
        <br />
        <FormGroup>
          <label>Site URL:</label>
          <RadioGroup
            row
            value={urlType}
            onChange={(e) => setUrlType(e.target.value)}
          >
            <FormControlLabel
              disabled={clubInfo}
              value="host"
              control={<Radio />}
              label="Host"
            />
            <FormControlLabel
              disabled={clubInfo}
              value="path"
              control={<Radio />}
              label="Path"
            />
          </RadioGroup>

          {urlType === "host" && (
            <TextField
              required
              disabled={clubInfo}
              InputLabelProps={{ shrink: true }}
              label="Host"
              value={host}
              placeholder="eg. hjerteress.com"
              onChange={(e) => setHost(e.target.value)}
            />
          )}

          {urlType === "path" && (
            <TextField
              required
              disabled={clubInfo}
              InputLabelProps={{ shrink: true }}
              label="Path"
              placeholder="eg. if '704', the site will be bridge.no/704"
              value={path}
              onChange={(e) => setPath(e.target.value)}
            />
          )}
        </FormGroup>
        <br />
        {loading && <span>...</span>}
        {clubInfo && (
          <Alert>
            <Table>
              <tr>
                <td>
                  <label>Name:</label>
                </td>
                <td>
                  {" "}
                  <span>{clubInfo.name}</span>
                </td>
              </tr>

              {siteType === "club" && (
                <tr>
                  <td>
                    <label>Club No.:</label>
                  </td>
                  <td>
                    <span>{clubInfo.klubbnummer}</span>
                  </td>
                </tr>
              )}
              <tr>
                <td>
                  <label>Krets No.:</label>
                </td>
                <td>
                  <span>{clubInfo.kretsnummer}</span>
                </td>
              </tr>
              {urlType === "host" && (
                <tr>
                  <td>
                    <label>Host:</label>
                  </td>
                  <td>
                    <span>{host}</span>
                  </td>
                </tr>
              )}
              {urlType === "path" && (
                <tr>
                  <td>
                    <label>Path:</label>
                  </td>
                  <td>
                    <span>{path}</span>
                  </td>
                </tr>
              )}
            </Table>

            <div>
              <Button onClick={submit}>Submit</Button>
              <Button
                onClick={() => {
                  setClubInfo(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </Alert>
        )}
        {!clubInfo && (
          <div>
            <Button onClick={check}>Check to confirm</Button>
            <Link href="/club-sites">
              <Button>Cancel</Button>
            </Link>
          </div>
        )}
      </Box>
    </Container>
  );
};
