import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  Block,
  CheckCircle,
  Close,
  Delete,
  Edit,
  MoreHorizOutlined,
  People,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Pagination,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { UpdateClub } from "./UpdateClub";
import { AssignUser } from "../../components/clubs/AssignUser";
import { EnableFooter } from "./list-action/EnableFooter";
import { fetchWithAuth } from "../../components/util/login";

export const ClubSites = () => {
  const [list, setList] = useState<any[]>([]);
  const [sortbyField, setSortByField] = useState("id");
  const [sortbyOrder, setSortByOrder] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState<any[]>([]);

  const itemsPerPage = 10;

  const [siteType, setSiteType] = useState("club");

  const [actionType, setActionType] = useState("");
  const [actionData, setActionData] = useState<any>(null);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [menuID, setMenuID] = useState(0);

  const fetchList = () => {
    fetchWithAuth(
      "/api/clubadmin/site/site_list?siteType=" +
        siteType +
        "&sortby=" +
        sortbyField +
        "," +
        sortbyOrder
    )
      .then((res) => res.json())
      .then((data) => {
        setList(data);
      });
  };

  useEffect(() => {
    if (list.length > 0) {
      fetchUsers();
    }
  }, [list]);

  const fetchUsers = () => {
    const orgIds: string[] = [];
    for (const item of list) {
      if (!orgIds.includes(item.org_id)) {
        orgIds.push(item.org_id);
      }
    }
    fetchWithAuth(
      "/api/clubadmin/users/list?sitetype=" +
        siteType +
        "&orgid=" +
        orgIds.join(",")
    )
      .then((res) => res.json())
      .then((data) => {
        setUsers(data.data);
      });
  };

  const enable = (id: string, enable: string) => {
    fetchWithAuth(
      "/api/clubadmin/site/enable_site?id=" + id + "&enabled=" + enable
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error === false) {
          setActionType("");
          setActionData(null);
          fetchList();
        } else {
          window.alert(data.data);
        }
      });
  };

  useEffect(() => {
    fetchList();
  }, [siteType, sortbyField, sortbyOrder]);

  const deleteSite = (id: number) => {
    fetchWithAuth("/api/clubadmin/site/site_delete?id=" + id, "", "POST")
      .then((res) => res.json())
      .then((data) => {
        if (data.error === false) {
          fetchList();
        } else {
          window.alert("Error: " + data.message);
        }
      });
  };

  const closeMenu = () => {
    setMenuID(0);
    setMenuAnchor(null);
  };

  const deleteUser = (id: number, name: string, orgName: string) => {
    if (
      window.confirm("Confirm to remove " + name + " from " + orgName + "?")
    ) {
      fetchWithAuth("/api/clubadmin/users/remove?id=" + id, "", "POST")
        .then((res) => res.json())
        .then((data) => {
          if (data.error === false) {
            fetchList();
          } else {
            window.alert("Error: " + data.message);
          }
        });
    }
  };

  return (
    <Container sx={{ p: 3 }}>
      <Typography variant="h4" component="h4">
        Hjemmesider
      </Typography>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: "background.default" }}>
        <Stack direction="row" justifyContent="end">
          <Link href="club-sites/add">
            <Button variant="contained">Ny side</Button>
          </Link>
        </Stack>

        {actionType && actionData && (
          <Dialog
            open={actionType && actionData ? true : false}
            fullWidth
            onClose={() => {
              setActionType("");
              setActionData(null);
            }}
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                setActionType("");
                setActionData(null);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
            <DialogTitle>
              {actionType === "update" ? "Update" : ""}{" "}
              {actionType === "assign" ? "Assign to" : ""} {actionData.org_name}
            </DialogTitle>
            <DialogContent>
              {actionData && actionType === "update" && (
                <UpdateClub
                  onCancel={() => {
                    setActionType("");
                    setActionData(null);
                  }}
                  onSuccess={() => {
                    fetchList();
                    setActionType("");
                    setActionData(null);
                  }}
                  data={actionData}
                />
              )}
              {actionData && actionType === "assign" && (
                <AssignUser
                  data={actionData}
                  onCancel={() => {
                    setActionType("");
                    setActionData(null);
                  }}
                  onSuccess={() => {
                    fetchUsers();
                    setActionType("");
                    setActionData(null);
                  }}
                />
              )}
              {actionData && actionType === "disable" && (
                <div>
                  Confirm to {actionData.enabled ? "disable?" : "enable?"} id:{" "}
                  {actionData.id}
                  <br />
                  <br />
                  <div style={{ textAlign: "right" }}>
                    <Button
                      onClick={() => {
                        setActionType("");
                        setActionData(null);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        enable(actionData.id, actionData.enabled ? "0" : "1");
                      }}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              )}
            </DialogContent>
          </Dialog>
        )}

        <Stack direction="row">
          <label onClick={() => setSiteType("club")}>
            <Radio checked={siteType === "club"} />
            Klubb
          </label>
          <label onClick={() => setSiteType("krets")}>
            <Radio checked={siteType === "krets"} />
            Krets
          </label>
          <label onClick={() => setSiteType("independent")}>
            <Radio checked={siteType === "independent"} />
            Independent
          </label>
        </Stack>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Button
                  onClick={() => {
                    setSortByField("id");
                    setSortByOrder(sortbyOrder ? 0 : 1);
                  }}
                >
                  {sortbyField === "id" && (
                    <>
                      {sortbyOrder === 1 ? (
                        <ArrowDropUpOutlined />
                      ) : (
                        <ArrowDropDownOutlined />
                      )}
                    </>
                  )}
                  ID
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="text"
                  onClick={() => {
                    setSortByField("org_name");
                    setSortByOrder(sortbyOrder ? 0 : 1);
                    setCurrentPage(1);
                  }}
                >
                  {sortbyField === "org_name" && (
                    <>
                      {sortbyOrder === 1 ? (
                        <ArrowDropUpOutlined />
                      ) : (
                        <ArrowDropDownOutlined />
                      )}
                    </>
                  )}
                  Name
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="text"
                  onClick={() => {
                    setSortByField("org_id");
                    setSortByOrder(sortbyOrder ? 0 : 1);
                    setCurrentPage(1);
                  }}
                >
                  {sortbyField === "org_id" && (
                    <>
                      {sortbyOrder === 1 ? (
                        <ArrowDropUpOutlined />
                      ) : (
                        <ArrowDropDownOutlined />
                      )}
                    </>
                  )}
                  Org No.
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="text"
                  onClick={() => {
                    setSortByField("enabled");
                    setSortByOrder(sortbyOrder ? 0 : 1);
                    setCurrentPage(1);
                  }}
                >
                  {sortbyField === "enabled" && (
                    <>
                      {sortbyOrder === 1 ? (
                        <ArrowDropUpOutlined />
                      ) : (
                        <ArrowDropDownOutlined />
                      )}
                    </>
                  )}
                  Enabled
                </Button>
              </TableCell>
              <TableCell>Domain</TableCell>
              <TableCell>Site ID</TableCell>
              <TableCell>Site editors</TableCell>
              <TableCell>Own footer</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list
              .slice(
                (currentPage - 1) * itemsPerPage,
                (currentPage - 1) * itemsPerPage + itemsPerPage
              )
              .map((item: any) => (
                <TableRow>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={
                        "http://" +
                        (item.host || "test.bridge.no/" + item.basePath)
                      }
                    >
                      {item.org_name}
                    </a>
                  </TableCell>
                  <TableCell>{item.org_id}</TableCell>
                  <TableCell>
                    {item.enabled ? (
                      <CheckCircle style={{ color: "green" }} />
                    ) : (
                      <Block style={{ color: "red" }} />
                    )}
                  </TableCell>
                  <TableCell>
                    {item.host ? (
                      <CheckCircle style={{ color: "green" }} />
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell>{item.root}</TableCell>
                  <TableCell>
                    {item.root !== 525 && (
                      <>
                        {users
                          .filter((userItem) => userItem.org_id === item.org_id)
                          .map((user) => (
                            <div>
                              <span>
                                {user.user_member_no} {user.user_name}
                              </span>
                              <IconButton
                                title="Remove from editor of this organization"
                                onClick={() =>
                                  deleteUser(
                                    user.id,
                                    user.user_name,
                                    item.org_name
                                  )
                                }
                              >
                                <Delete />
                              </IconButton>
                            </div>
                          ))}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <EnableFooter
                      id={item.id}
                      enabled={item.footer ? true : false}
                      onSuccess={() => fetchList()}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      title="Update"
                      onClick={() => {
                        setActionData(item);
                        setActionType("update");
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        setMenuID(item.id);
                        setMenuAnchor(e.currentTarget);
                      }}
                    >
                      <MoreHorizOutlined />
                    </IconButton>
                    <Menu
                      open={menuAnchor && menuID === item.id ? true : false}
                      anchorEl={menuAnchor}
                      onClose={closeMenu}
                    >
                      <MenuItem
                        onClick={() => {
                          setActionData(item);
                          setActionType("assign");
                          closeMenu();
                        }}
                      >
                        <ListItemIcon>
                          <People />
                        </ListItemIcon>
                        <ListItemText>Assign</ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setActionData(item);
                          setActionType("disable");
                          closeMenu();
                        }}
                      >
                        <ListItemIcon>
                          {item.enabled ? <Block /> : <CheckCircle />}
                        </ListItemIcon>
                        <ListItemText>
                          {item.enabled ? "Disable" : "Enable"}
                        </ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          closeMenu();
                          if (
                            window.confirm(
                              "Confirm to delete id " + item.id + "?"
                            )
                          ) {
                            deleteSite(item.id);
                          }
                        }}
                      >
                        <ListItemIcon>
                          <Delete />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <br />
      <Stack spacing={2} direction="row" justifyContent={"end"}>
        <Pagination
          page={currentPage}
          count={Math.ceil(list.length / itemsPerPage)}
          onChange={(e, p: number) => setCurrentPage(p)}
        />
        <div>Total {list.length} sites</div>
      </Stack>
    </Container>
  );
};
