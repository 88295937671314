import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Switch,
} from "@mui/material";
import { useState } from "react";
import { fetchWithAuth } from "../../../components/util/login";

export const EnableFooter = (props: {
  id: number;
  enabled: boolean;
  onSuccess: () => void;
}) => {
  const { id, enabled } = props;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const confirm = () => {
    //send request
    setLoading(true);
    fetchWithAuth(
      "/api/clubadmin/site/enable_footer?id=" +
        id +
        "&status=" +
        (enabled ? 0 : 1)
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error === false) {
          props.onSuccess();
        } else {
          console.error(data);
        }
        setLoading(false);
        setOpen(false);
      });
  };

  return (
    <div>
      <Switch
        checked={enabled}
        onClick={() => setOpen(true)}
        title={enabled ? "Disable own footer" : "Enable own footer"}
      />
      <Dialog open={open} title="Change footer">
        <DialogContent>
          Are you sure to{" "}
          {enabled ? "disable site footer" : "enable site footer"}?
          <br />
          {loading && <CircularProgress size={14} />}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={confirm} autoFocus>
            Confirm
          </Button>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
