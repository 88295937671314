export const setToken = (token: string) => {
  localStorage.setItem("refresh-token", token);
};

export const getToken = () => {
  const rToken = localStorage.getItem("refresh-token");
  return rToken;
};

export let accessToken = "";

const getAccessToken = async () => {
  const refreshToken = getToken();
  if (!refreshToken) {
    return "";
  }

  const res = await fetch(
    process.env.REACT_APP_REMOTE_URL +
      "/auth/token/access/renew?token=" +
      refreshToken
  );

  const data = await res.json();

  if (data.error === true) {
    return "";
  } else {
    accessToken = data.data;
  }
};

const fetchWithAuth = (url: string, body?: any, method?: string) => {
  return fetch(url, {
    headers: { Authorization: "Bearer " + accessToken },
    body: body,
    method: method || "GET",
  });
};

export { getAccessToken, fetchWithAuth };
